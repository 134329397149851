import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Us presentem el nou curs presencial de preparació per l'accés a Mossos
                    d'Esquadra 2024/25 en format intensiu.
                </p>

                <p>
                    <span role="img" aria-label="Calendari">
                        📅
                    </span>{" "}
                    <strong>Dissabtes i diumenges de 15:30 a 20:30h.</strong>
                </p>

                <p>
                    <span role="img" aria-label="Graduació">
                        🎓
                    </span>{" "}
                    <strong>
                        Ara ja pots reservar la teva plaça i reforçar els teus coneixements aquest
                        estiu amb el CAMPUS online.
                    </strong>
                </p>

                <p>
                    A iOpos tenim la nostra pròpia metodologia i any rere any obtenim els millors
                    resultats.
                </p>

                <p>
                    <span role="img" aria-label="Estudiants">
                        🧑‍🤝‍🧑
                    </span>{" "}
                    <strong>Tots els alumnes inicien i finalitzen junts el curs</strong>, això ens
                    permet que assoleixin tots els continguts de la manera més eficient.
                </p>

                <p>
                    <span role="img" aria-label="Llibres">
                        📚
                    </span>{" "}
                    <strong>La formació consta de:</strong>
                </p>

                <p>
                    - Accés a un campus online amb tota l'actualitat setmanal en forma de notícies,
                    test per posar-vos a prova i vídeo resum setmanal.
                </p>

                <p>- Formació didàctica i simulacres.</p>

                <p>
                    - <strong>30 hores de classes presencials</strong> amb explicacions dels
                    conceptes més susceptibles de sortir en examen tant a nivell teòric com de
                    psicotècnics aptitudinals.
                </p>

                <p>- Accés a un grup exclusiu de WhatsApp amb reptes setmanals.</p>

                <p>
                    - Contacte directe amb la docent que imparteix el curs per resoldre qualsevol
                    dubte.
                </p>

                <p>
                    <span role="img" aria-label="Guia de llibres">
                        📕
                    </span>{" "}
                    <strong>
                        El primer dia de curs us donarem la nostra guia de Mossos d'Esquadra en
                        paper i un dossier de psicotècnics confeccionat pel nostre equip de
                        psicòlegs.
                    </strong>
                </p>

                <p>
                    <span role="img" aria-label="Coet">
                        🚀
                    </span>{" "}
                    <strong>Inici:</strong> Dissabte 28 de setembre de 15:30 a 20:30h
                </p>

                <p>
                    <span role="img" aria-label="Calendari">
                        🗓️
                    </span>{" "}
                    Diumenge 29 de setembre de 15:30 a 20:30h
                </p>

                <p>
                    <span role="img" aria-label="Calendari">
                        🗓️
                    </span>{" "}
                    Dissabte 19 d'octubre de 15:30 a 20:30h
                </p>

                <p>
                    <span role="img" aria-label="Calendari">
                        🗓️
                    </span>{" "}
                    Diumenge 20 d'octubre de 15:30 a 20:30h
                </p>

                <p>
                    <span role="img" aria-label="Calendari">
                        🗓️
                    </span>{" "}
                    Dissabte 9 de novembre de 15:30 a 20:30h
                </p>

                <p>
                    <span role="img" aria-label="Bandera de meta">
                        🏁
                    </span>{" "}
                    <strong>Fi de curs:</strong> Diumenge 10 de novembre de 15:30 a 20:30h.
                </p>

                <p>
                    <span role="img" aria-label="Localització">
                        📍
                    </span>{" "}
                    El curs es realitza al c/Martí Codolar núm. 18, Hospitalet de Llobregat.
                </p>

                <p>
                    <span role="img" aria-label="Advertència">
                        ⚠️
                    </span>{" "}
                    <strong>Les places són limitades!</strong>
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/R_9CjLJKROM"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate item={item} title="">
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
